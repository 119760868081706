<template>
  <nav class="nav-section">
    <aRow type="flex" justify="space-between">
      <aCol>
        <ul class="tabs">
          <li
            v-for="(tab, i) in packageTabs"
            :key="i"
            :class="activePackageTab === tab.name ? 'active' : ''"
            @click="onClickChangeTab(tab.name)"
          >
            {{ tab.name }}
          </li>
        </ul>
      </aCol>
      <aCol>
        <span class="c-pointer">


          <a-popconfirm
            v-if="$route.name !== 'products-budget-view'"
            ok-text="Sim"
            cancel-text="Não"
            placement="left"
            @confirm="onConfirmCreateProductBudget"
          >
            <template #title>
              Deseja criar um orçamento <br />
              para este produto?"
            </template>

            <a-button size="small" class="mr-10 f12 relative" type="primary" style="top:-3px">Gerar Orçamento</a-button>
          </a-popconfirm>


          <a-icon
            v-if="$root.isAdmin()"
            type="edit"
            class="f20 icon mr-10"
            @click="
              $router.push(
                $route.name === 'products-budget-view'
                  ? `/products-budget/edit/${tempProduct.id}`
                  : `/products/edit/${tempProduct.id}`
              )
            "
          />

          <span v-if="tempProduct.imageBase64" @click="generatePDF">
            <i class="fa-solid fa-file-pdf f20" style="color: #ff2026" />
          </span>
          <span v-if="tempProduct.imageBase64 === undefined">
            <a-icon type="loading" />
          </span>
        </span>
      </aCol>
    </aRow>
  </nav>
</template>

<script>
// libs
import html2pdf from "html2pdf.js";

export default {
  name: "ViewPackageTabsSection",
  props: {
    tempProduct: Object,
    activePackageTab: String,
  },

  data() {
    return {
      packageTabs: [
        {
          name: "Informações",
        },
        {
          name: "Programação",
        },
      ],
      generatePDFLoading: false,
    };
  },
  methods: {
    onClickChangeTab(tab) {
      this.activePackageTab = tab;
      this.$emit("onClickChangeTab", tab);
    },
    generatePDF() {
      this.generatePDFLoading = true;

      const element = document.getElementById("pdf-wrapper");

      const options = {
        filename: `${this.tempProduct.id} - ${this.tempProduct.name}.pdf`,
        margin: [5, 0, 8, 0],
        image: { type: "jpeg", quality: 1 },
        // pagebreak: { mode: "avoid-all", after: ".avoidThisRow" },
        pagebreak: { mode: ["avoid-all", "css", "legacy"] },
        html2canvas: {
          scale: 2,
          allowTaint: true,
          scrollX: 0,
          scrollY: 0,
          dpi: 300,
          letterRendering: true,
          useCORS: true,
        },
        jsPDF: {
          format: "a4",
          orientation: "portrait",
        },
      };

      html2pdf()
        .from(element)
        .set(options)
        .toPdf()
        .get("pdf")
        .then(function (pdf) {
          var totalPages = pdf.internal.getNumberOfPages();
          console.log(pdf);
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(7);
            pdf.setTextColor(150);
            //Add you content in place of example here
            pdf.text(
              "As informações existentes nesta mensagem e nos arquivos anexados são para uso restrito. A utilização, divulgação, cópia ou distribuição dessa mensagem por qualquer\npessoa diferente do destinatário é proibida. Se essa mensagem foi recebida por engano, favor excluí-la e informar ao remetente pelo endereço eletrônico acima.",
              pdf.internal.pageSize.getWidth() - 200,
              pdf.internal.pageSize.getHeight() - 8
            );

            pdf.text(
              i + " de " + totalPages,
              pdf.internal.pageSize.getWidth() - 10,
              pdf.internal.pageSize.getHeight() - 5
            );

            //           pdf.setPage(i);
            //  pdf.setFontSize(10);
            //  pdf.setTextColor(150);
            //  //divided by 2 to go center
            //  pdf.text('Page ' + i + ' of ' + totalPages, pdf.internal.pageSize.getWidth()/2,
            //  pdf.internal.pageSize.getHeight()/ 2);
          }
        })
        .save();

      this.generatePDFLoading = false;
    },
    onConfirmCreateProductBudget() {
      let productBudget = this.tempProduct;
      productBudget.user_id = this.$store.state.userData.id;
      productBudget.status = "draft";
      productBudget.situation = "";

      this.$http
        .post("/product-budget/create", productBudget)
        .then(({ data }) => {
          this.$message.success(data.message);
          this.$router.push(`/products-budget/edit/${data.id}`);
        })
        .catch(({ response }) => {
          this.$message.error(response.data.message);
        });
    },
  },
};
</script>

<style lang="sass" scoped>
.nav-section
  margin-top: -30px
  background: #fff
  padding: 10px
  margin-bottom: 20px
  .icon
    color: #bbb
    transition: .3s
    &:hover
      color: #e09042
  .tabs
    padding: 0
    margin: 0
    li
      font-size: 13px
      font-weight: 500
      cursor: pointer
      display: inline-block
      padding: 0 10px
      border-right: 1px solid #ddd
      margin: 0
      &.active
        color: #e09042
      &:last-child
        border: 0
</style>
