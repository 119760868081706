<template>
  <article class="bg">
    <div v-if="tempProduct.status === 'published'">
      <div
        class="banner"
        v-if="tempProduct.id !== undefined"
        :style="`background-image: url(${tempProduct.image}`"
      />
      <div v-if="tempProduct.type === 'package'" class="container">
        <ViewPackageTabsSection
          :tempProduct="tempProduct"
          :activePackageTab="activePackageTab"
          @onClickChangeTab="onClickChangeTab"
        />
        <aRow :gutter="20">
          <aCol class="mb-20" :span="18">
            <ViewPackageContentSection
              v-if="tempProduct.id !== undefined"
              :activePackageTab="activePackageTab"
              :tempProduct="tempProduct"
            />
          </aCol>
          <aCol class="mb-20" :span="6">
            <ViewPackageSidebarSection
              v-if="tempProduct.id"
              :tempProduct="tempProduct"
            />
          </aCol>
        </aRow>
      </div>
    </div>
    <div v-else class="pd-20">
      <a-alert
        v-if="tempProduct.status"
        type="warning"
        message="Este produto ainda não foi publicado. Volte na edição e altere o status para publicado"
        banner
      />
    </div>

    <div class="product-pdf">
      <PackagePDFSection
        v-if="tempProduct.type === 'package'"
        :tempProduct="tempProduct"
        :product="product"
      />
    </div>
  </article>
</template>

<script>
// components
import ViewPackageTabsSection from "@/components/products/sections/package/ViewPackageTabsSection.vue";
import ViewPackageContentSection from "@/components/products/sections/package/ViewPackageContentSection.vue";
import ViewPackageSidebarSection from "@/components/products/sections/package/ViewPackageSidebarSection.vue";
import PackagePDFSection from "@/components/products/sections/package/PackagePDFSection.vue";

// reuse
import productReusables from "@/components/products/mixins/productReusables.js";

export default {
  name: "ProuductViewPackagePage",
  components: {
    ViewPackageTabsSection,
    ViewPackageContentSection,
    ViewPackageSidebarSection,
    PackagePDFSection,
  },
  mixins: [productReusables],
  data() {
    return {
      activePackageTab: "Informações",
    };
  },
  computed: {
    routeTab() {
      let type = "Produto";
      if (this.$route.name === "products-budget-view") type = "Orçamento";
      return {
        title: `${type} ${
          this.product.details.id ? this.product.details.id : ""
        }`,
      };
    },
  },
  async created() {
    if (this.$route.name === "products-budget-view") this.isBudget = true;
    await this.getProductById(this.$route.params.id, false);
    await this.getProductById(this.$route.params.id, true);
  },
  methods: {
    onClickChangeTab(tab) {
      this.activePackageTab = tab;
    },
  },
};
</script>

<style lang="sass" scoped>
.product-pdf
  margin: 0 auto
  width: 860px
  height: 1px
  overflow: auto
.bg
  min-height: 900px
.container
  margin: 0 auto
  width: 1100px
.banner
  height: 340px
  background: #da366f
  background: linear-gradient(-180deg,#da366f 18%,#ffb000)
  background-size: cover
  background-position: center
</style>
