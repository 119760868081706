<template>
  <div>
    <section class="pdf-hidden" id="pdf-wrapper">
      <table width="100%">
        <thead>
          <tr>
            <td width="30%" style="padding: 0px 0 20px 60px">
              <img :src="product.details?.company_branch?.logo" width="85" />
            </td>
            <td
              width="70%"
              style="
                color: #333;
                font-size: 10px;
                padding: 0px 60px 20px 0;
                text-align: right;
              "
            >
              <b>{{ product.details.company.company_name }}</b
              ><br />
              {{ product.details.company.cnpj }}<br />
              {{ product.details.company.address }}<br />
              {{ product.details.company_branch.phone }}
            </td>
          </tr>
        </thead>
      </table>

      <section class="relative mb-10">
        <img :src="tempProduct.imageBase64" width="100%" />
        <div class="name">{{ tempProduct.name }}</div>
      </section>

      <section class="section">
        <aRow>
          <aCol
            class="mb-10"
            v-if="tempProduct.package_schedules === 'Mínimo de noites/dias'"
            :span="24"
          >
            <b><i class="fa-regular el-color fa-calendar-days"></i> Período:</b>

            <span>
              {{ parseInt(tempProduct.package_min_nights) + 1 }} dias /
              {{ tempProduct.package_min_nights }} noites
            </span>
          </aCol>
          <aCol :span="24" class="weekdays mb-10">
            <b><i class="fa-regular el-color fa-calendar-check"></i> Saídas:</b>
            <span v-if="tempProduct.package_departures === 'Dias da semana'">
              <font class="day" v-for="(day, i) in packageWeekdays" :key="i">
                <span v-if="i !== 0">,</span> {{ day }}
              </font>
            </span>
            <span v-else> Diárias </span>
          </aCol>
        </aRow>

        <div
          v-if="tempProduct.package_type === 'Pacote completo com Aéreo'"
          class="places mb-10"
        >
          <b>
            <i class="fa-solid el-color fa-plane-departure"></i> Saindo de:</b
          >
          {{ tempProduct.package_airport }}
        </div>

        <div class="religion mb-10">
          <b> <i class="fa-solid el-color fa-church"></i> Religião:</b>
          {{ tempProduct.package_religion }}
        </div>

        <div class="places mb-20">
          <b> <i class="fa-solid el-color fa-earth-africa"></i> Visitando:</b>
          <font
            class="day"
            v-for="(destination, i) in packageDestinations"
            :key="i"
            ><span v-if="i !== 0">|</span> {{ destination }}
          </font>
        </div>

        <div
          class="desc"
          v-if="tempProduct.description"
          v-html="tempProduct.description"
        />
      </section>

      <section v-if="packageIncludesRows.length" class="section-1 section">
        <div class="mb-10"><b>O pacote inclui: </b></div>
        <div v-if="packageIncludesRows.length" class="includes">
          <ul class="list">
            <li
              v-for="({ id }, i) in packageIncludesRows"
              :key="i"
              class="item"
            >
              <i class="fa-solid fa-check-double"></i>
              {{ tempProduct[`package_includes_${id}`] }}
            </li>
          </ul>
        </div>
      </section>

      <section v-if="packageDifferentialsRows.length" class="section-2 section">
        <div class="mb-10"><b>Diferenciais: </b></div>
        <ul class="list">
          <li
            v-for="({ id }, i) in packageDifferentialsRows"
            :key="i"
            class="item"
          >
            <a-icon class="el-color" type="double-right" />
            {{ tempProduct[`package_differentials_${id}`] }}
          </li>
        </ul>
      </section>

      <section
        v-if="tempProduct.package_observations"
        class="section-3 section"
      >
        <div v-html="tempProduct.package_observations"></div>
      </section>

      <section v-if="packageScheduleRows.length" class="section-3 section">
        <div class="mb-10"><b>Programação: </b></div>

        <div class="schedules">
          <aRow
            v-for="({ id }, i) in packageScheduleRows"
            :key="i"
            class="schedule"
            :gutter="20"
          >
            <aCol :span="3" class="day">{{ id }}° DIA:</aCol>
            <aCol :span="21">
              <h3 class="schedule-title f16">
                {{ tempProduct[`package_schedule_${id}_title`] }}
              </h3>
              <div
                class="desc f13"
                v-html="tempProduct[`package_schedule_${id}_description`]"
              ></div>
            </aCol>
          </aRow>
        </div>
      </section>

      <section class="section-4 section pb-20">
        <div class=""><b>Valor total do pacote: </b></div>

        <div class="price el-color mb-30">
          {{ tempProduct.currency | formatCurrency }}
          {{ tempProduct.value | formatPrice }}
        </div>
      </section>

      <section class="section-4 section pb-20">
        <div class=""><b>Consultor responsável: </b></div>

        <div class="mb-30 f13">
          Nome: {{ tempProduct.user.first_name }} {{ tempProduct.user.last_name
          }}<br />
          Contato: {{ tempProduct.user.mobile_phone }}<br />
          E-mail: {{ tempProduct.user.email }}<br />
        </div>
      </section>
    </section>
  </div>
</template>

<script>
// reuse
import reusableThings from "@/mixins/general/reusableThings";

export default {
  name: "ProductPdf",
  props: {
    tempProduct: Object,
    product: Object,
  },
  mixins: [reusableThings],
  data() {
    return {
      packageIncludesRows: [],
      packageWeekdays: [],
      packageDestinations: [],
      packageDepartureDates: [],
      packageReturnDates: [],
      packageDifferentialsRows: [],
      packageScheduleRows: [],
    };
  },
  mounted() {
    this.packageIncludesRows = this.tempProduct.package_schedule_rows
      ? JSON.parse(this.tempProduct.package_schedule_rows)
      : [];
    this.packageWeekdays = this.tempProduct.package_weekdays
      ? JSON.parse(this.tempProduct.package_weekdays)
      : [];
    this.packageDestinations = this.tempProduct.package_destinations
      ? JSON.parse(this.tempProduct.package_destinations)
      : [];
    this.packageDepartureDates = this.tempProduct.package_departure_dates
      ? JSON.parse(this.tempProduct.package_departure_dates)
      : [];
    this.packageReturnDates = this.tempProduct.package_return_dates
      ? JSON.parse(this.tempProduct.package_return_dates)
      : [];
    this.packageDifferentialsRows = this.tempProduct.package_differentials_rows
      ? JSON.parse(this.tempProduct.package_differentials_rows)
      : [];
    this.packageScheduleRows = this.tempProduct.package_schedule_rows
      ? JSON.parse(this.tempProduct.package_schedule_rows)
      : [];

    setTimeout(() => {
      const newDepartureArray = this.packageDepartureDates.map((date) => {
        return this.formatMultiDates(date);
      });

      this.packageDepartureDates = newDepartureArray;

      const newReturnArray = this.packageReturnDates.map((date) => {
        return this.formatMultiDates(date);
      });

      this.packageReturnDates = newReturnArray;
    }, 100);
  },
  methods: {},
};
</script>

<style lang="sass">
#pdf-wrapper
  p
    margin-bottom: 10px
</style>

<style lang="sass" scoped>
#pdf-wrapper
  font-size: 16px
  color: #000
  background: #FFF
  .el-color
    color:  #00ccb2
  .section
    padding: 20px 60px 0
  .price
    font-size: 34px
    letter-spacing: -2px
    font-weight: 600
  .name
    position: absolute
    bottom: 30px
    left: 60px
    right: 60px
    line-height: 1
    font-size: 54px
    color: #FFF
    text-transform: uppercase
    letter-spacing: -2px
    font-weight: 600
    text-shadow: 0 0px 30px #333
  .list
    padding: 0
    margin: 0
    list-style: none
    .item
      padding: 0 0 8px 0
      margin: 0
      color: #333
      .fa-check-double
        color:  #00ccb2
</style>
