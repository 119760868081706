<template>
  <aside class="sidebar">
    <div class="widget">
      <div class="price">
        <h3 class="title">Valor total do pacote:</h3>
        {{ tempProduct.currency | formatCurrency }}
        {{ tempProduct.value | formatPrice }}
      </div>
    </div>
    <div class="widget">
      <h3 class="title">Diferenciais</h3>
      <ul class="differentials">
        <li
          v-for="({ id }, i) in packageDifferentialsRows"
          :key="i"
          class="item"
        >
          <a-icon type="double-right" />
          {{ tempProduct[`package_differentials_${id}`] }}
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import reusableThings from "@/mixins/general/reusableThings";
export default {
  name: "ViewPackageSidebarSection",
  props: {
    tempProduct: Object,
  },
  mixins: [reusableThings],
  data() {
    return {
      packageDifferentialsRows: [],
    };
  },
  mounted() {
    this.packageDifferentialsRows = this.tempProduct.package_differentials_rows
      ? JSON.parse(this.tempProduct.package_differentials_rows)
      : [];
  },
  methods: {},
};
</script>

<style lang="sass" scoped>
.widget
  background: #fff
  padding: 10px
  margin-bottom: 20px
  .price
    color: #e09042
    font-size: 34px
    letter-spacing: -1px
    font-weight: 600
  .title
    color: #00ccb2!important
    font-size: 14px
    margin-bottom: 10px
    padding-bottom: 6px
    border-bottom: 1px solid #eee
  .differentials
    padding: 0
    margin: 0
    list-style: none
    .item
      padding: 0 0 8px 0
      margin: 0
      font-size: 13px
      color: #333
      i
        color:  #00ccb2
        font-size: 10px
</style>
