<template>
  <section class="tab-content">
    <ViewPackageDataTab
      v-if="activePackageTab === 'Informações'"
      :tempProduct="tempProduct"
    />
    <ViewPackageScheduleTab
      v-if="
        activePackageTab === 'Programação' &&
        tempProduct.package_schedule_rows !== undefined
      "
      :tempProduct="tempProduct"
    />
  </section>
</template>

<script>
import ViewPackageScheduleTab from "../../tabs/package/ViewPackageScheduleTab.vue";
import ViewPackageDataTab from "../../tabs/package/ViewPackageDataTab.vue";

export default {
  components: { ViewPackageScheduleTab, ViewPackageDataTab },
  name: "ViewPackageContentSection",
  props: {
    tempProduct: Object,
    activePackageTab: String,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass" scoped>
.tab-content
  background: #fff
  padding: 10px
</style>
