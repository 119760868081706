<template>
  <div class="tab">
    <h2 class="title">Programação</h2>
    <div class="schedules">
      <aRow
        v-for="({ id }, i) in packageScheduleRows"
        :key="i"
        class="schedule"
        :gutter="20"
      >
        <aCol :span="3" class="day">{{ id }}° DIA:</aCol>
        <aCol :span="21">
          <h3 class="schedule-title">
            {{ tempProduct[`package_schedule_${id}_title`] }}
          </h3>
          <div
            class="desc"
            v-html="tempProduct[`package_schedule_${id}_description`]"
          ></div>
        </aCol>
      </aRow>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewPackageScheduleTab",
  props: {
    tempProduct: Object,
  },
  data() {
    return {
      packageScheduleRows: [],
    };
  },
  mounted() {
    this.packageScheduleRows = this.tempProduct.package_schedule_rows
      ? JSON.parse(this.tempProduct.package_schedule_rows)
      : [];
  },
};
</script>

<style lang="sass" scoped>
.tab
  color: #333
  .title
    color: #e09042 !important
    font-size: 28px
    letter-spacing: -1px
    font-weight: 600
    margin-bottom: 30px
  .schedules
    .schedule
      border-bottom: 1px solid #eee
      margin-bottom: 20px
      .schedule-title
        color: #e09042 !important
        font-size: 16px
        letter-spacing: 0
        margin: 0 0 10px
        font-weight: 600
        padding: 0
      .day
        color: #333
        font-size: 16px
        font-weight: 600

      &:last-child
        border: 0
</style>
